import '../polyfills'

import { type FC, type PropsWithChildren } from 'react'

import { AdServerConfigurationContextProvider } from '../ad-server'
import { CustomSwrConfig } from '../base-api-communication'
import { DeviceDetectorContextProvider } from '../device-detector'
import { ExpaContextProvider } from '../expa'
import { ExperimentsContextProvider } from '../experiments/components/ExperimentsContextProvider'
import { FeatureToggleProvider } from '../feature-toggles'
import { FrontendTestsHydrationHint } from '../frontend-tests'
import { GlobalConfigContextProvider } from '../global-config'
import { GtmProvider } from '../gtm'
import { I18nProvider } from '../i18n'
import { SaeConversationalChat } from '../sae-conversational-chat'
import {
  SeoAppMetaAndLinkTags,
  SeoBreadcrumbListJsonLd,
  SeoGlobalConfigProvider,
  SeoGlobalJsonLd,
} from '../seo'
import { WebviewContextProvider } from '../webview'

import { type AppPageProps } from './getServerSideAppProps'

/**
 * Wrapper for every application's page (App & Pages routers). In place (`pages/_app.tsx` & `app/layout.tsx`)
 * add router specific functionality.
 */
export const AppLayout: FC<PropsWithChildren<AppPageProps>> = (props) => {
  const {
    adServerConfig,
    adServerTargeting,
    breadcrumbs,
    children,
    deviceInfo,
    expa,
    experiments,
    gtmConfig,
    gtmDataProps,
    isWebview,
    localeMessages,
    publicFeatureToggles,
    publicGlobalConfig,
    seoGlobalConfig,
    swrFallback,
  } = props

  /* eslint-disable react/jsx-max-depth -- app needs many providers */
  return (
    <>
      <GlobalConfigContextProvider publicGlobalConfig={publicGlobalConfig}>
        <I18nProvider
          locale={publicGlobalConfig.locale}
          localeMessages={localeMessages}
          timeZone={publicGlobalConfig.timeZone}
        >
          <DeviceDetectorContextProvider deviceInfo={deviceInfo}>
            <SeoGlobalConfigProvider value={seoGlobalConfig}>
              <ExpaContextProvider expa={expa}>
                <WebviewContextProvider value={isWebview}>
                  <ExperimentsContextProvider experiments={experiments}>
                    <AdServerConfigurationContextProvider
                      adServerConfig={adServerConfig}
                      adServerTargeting={adServerTargeting}
                    >
                      <FeatureToggleProvider
                        featureToggles={publicFeatureToggles}
                      >
                        <CustomSwrConfig fallback={swrFallback}>
                          <SeoAppMetaAndLinkTags />
                          <SeoGlobalJsonLd />
                          <SeoBreadcrumbListJsonLd
                            breadcrumbs={breadcrumbs ?? undefined}
                          />
                          {children}
                          <GtmProvider
                            gtmData={gtmDataProps}
                            gtmId={gtmConfig.gtmId}
                          />
                          <SaeConversationalChat />
                        </CustomSwrConfig>
                      </FeatureToggleProvider>
                    </AdServerConfigurationContextProvider>
                  </ExperimentsContextProvider>
                </WebviewContextProvider>
              </ExpaContextProvider>
            </SeoGlobalConfigProvider>
          </DeviceDetectorContextProvider>
        </I18nProvider>
      </GlobalConfigContextProvider>
      <FrontendTestsHydrationHint />
    </>
  )
}
